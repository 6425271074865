const templateConfig = {
    //所有幣值
    allCurrency: ['THB','VND2','INR','IDR2','IDR','MYR','CNY','PTS','PHP','USD','HKD','BRL','MMK','USDT', 'MXN', 'JPY', 'BDT', 'EUR', 'KRW', 'LAK2', 'PKR', 'PGK'],
    //所有電話國碼
    allTel: ['my','th','vn','us','in','id','jp','hk','sg', 'cn', 'pt', 'br', 'ph', 'pk', 'mm', 'au', 'mx', 'bd', 'kh', 'it', 'tz', 'kr', 'la', 'pg'],
    //所有語言
    allLang: ['th-TH','vi-VN','zh-CN','en-US','hi-IN','id-ID','ja-JP','ml-IN','ms-MY','my-MM','ta-IN','zh-TW','pt-BR', 'es-MX', 'km-KH', 'tl-PH', 'ko-KR', 'bn-BD'],
    //所有遊戲列表
    allGameList: {
      LIVE: ['SEXYBCRT', 'MX', 'ALLBET', 'SB', 'AG', 'BBIN', 'SA', 'VENUS', 'GD', 'WM', 'EVO', 'HY', 'DG', 'JG', 'MG', 'PT'],
      EGAME: ['AE_GAMING', 'PT', 'CQ9', 'VT', 'JDB', 'RT', 'AMEBA', 'SA', 'MG', 'LAX', 'SIMPLEPLAY', 'AG', 'GGAMING', 'MW', 'SEA', 'PS', 'JOKER', 'UFASLOT', 'KISS918', 'PP', 'PG', 'JILI', 'RW', 'RICH88', 'FC'],
      MPG: ['GGAMING', 'CQ9', 'JDB', 'MW', 'SIMPLEPLAY', 'PT', 'JILI', 'FC', 'YL', 'JOKER'],
      SPORTS: ['UGAMING', 'CROWN', 'SABA', 'SBOBET', 'CMDBET', 'BTI', 'UFABET', 'ZFB', 'AE_CRICKET'],
      ESPORTS: ['AE_ES2','ESPORTSBULL', 'TF'],
      CHESS: ['AE_C', 'CARD365', 'DS', 'JDB', 'KY', 'LC', 'VG', 'KINGMAKER', 'V8', 'RICH88', 'HA'],
      ANIMAL: ['S128', 'SV', 'TRC', 'RCB'],
      LOTTERY: ['AE_LOT', 'SA', 'VR', 'NY', 'BBIN', 'IG', 'VSL', 'GPI', 'GW'],
      MINI_GAME: ['AE_LOT', 'GW', 'GPI'],
      FINANCE: ['BO'],
    },
    //所有遊戲類型
    allGameType: [ 'LIVE','EGAME','SPORTS','MPG','ANIMAL','CHESS','LOTTERY','ESPORTS','MINI_GAME','FINANCE'],
    //所有gamelobby版型
    allGameTemp: {
      Live: [
        { name: 'live1', prefetch: true, thumbnailHeight: 1146},
        { name: 'live2', prefetch: true, thumbnailHeight: 927},
        { name: 'live3', prefetch: true, thumbnailHeight: 4840},
      ],
      Egame: [
        { name: 'egame1', prefetch: true, thumbnailHeight: 2387},
        { name: 'egame2', prefetch: true, thumbnailHeight: 2563},
        { name: 'egame3', prefetch: true, thumbnailHeight: 2408},
        { name: 'egame4', prefetch: true, thumbnailHeight: 2361},
        { name: 'egame5', thumbnailHeight: 2485},
        { name: 'egame6', thumbnailHeight: 2486},
        { name: 'egame7', thumbnailHeight: 2514},
        { name: 'egame8', thumbnailHeight: 2593},
        { name: 'egame9', thumbnailHeight: 3419},
        { name: 'egame10', thumbnailHeight: 2494},
        { name: 'bcgame', notShow: true},
      ],
      Sports: [
        { name: 'sports1', thumbnailHeight: 1294},
        { name: 'sports2', thumbnailHeight: 1231},
        { name: 'sports3', thumbnailHeight: 1040},
        { name: 'sports4', thumbnailHeight: 1344},
        { name: 'sports5', thumbnailHeight: 1090},
        { name: 'sports6', thumbnailHeight: 1090},
      ],
      Mpg: [
        { name: 'fishing1', thumbnailHeight: 1120},
        { name: 'fishing2', thumbnailHeight: 1434},
        { name: 'fishing3', thumbnailHeight: 1272},
        { name: 'fishing4', thumbnailHeight: 1266},
        { name: 'fishing5', thumbnailHeight: 1534},
        { name: 'bcgame', notShow: true},
      ],
      Animal: [
        { name: 'animal1', thumbnailHeight: 887},
        { name: 'animal2', thumbnailHeight: 940},
        { name: 'animal3', thumbnailHeight: 940},
      ],
      Lottery: [
        { name: 'lottery1', thumbnailHeight: 1202},
        { name: 'lottery2', thumbnailHeight: 1059},
        { name: 'lottery3', thumbnailHeight: 1059},
        { name: 'lottery4', thumbnailHeight: 1059},
        { name: 'lottery5', thumbnailHeight: 982},
        { name: 'lottery6', thumbnailHeight: 1272},
        { name: 'lottery7', thumbnailHeight: 942},
        { name: 'lottery8', thumbnailHeight: 1287},
      ],
      Esports: [
        { name: 'esports1', thumbnailHeight: 1844},
        { name: 'esports2', thumbnailHeight: 2994},
        { name: 'esports3', thumbnailHeight: 1844},
      ],
      Chess: [
        { name: 'chess1', thumbnailHeight: 2387},
        { name: 'chess2', thumbnailHeight: 2563},
        { name: 'chess3', thumbnailHeight: 2408},
        { name: 'chess4', thumbnailHeight: 2361},
        { name: 'chess5', thumbnailHeight: 2485},
        { name: 'chess6', thumbnailHeight: 2486},
        { name: 'chess7', thumbnailHeight: 2514},
        { name: 'chess8', thumbnailHeight: 2593},
        { name: 'chess9', thumbnailHeight: 3419},
        { name: 'chess10', thumbnailHeight: 2494},
        { name: 'bcgame', notShow: true},
      ],
      MINI_GAME: [
        { name: 'default', thumbnailHeight: 0 },
      ],
      Finance: [
        { name: 'default', thumbnailHeight: 0 },
      ],
      FloatingGames: [true],
    },
    //所有EC版型
    allEcTemp: [
      { name: 'ec10', thumbnailHeight: 1602, quickLogin: false, extra: true,  layout: ['Home','Header','Footer'],              color: { 'bg-color': '#ffffff', 'color-highlight': '#ffc21c', 'color-highlight2': '#ffc21c', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec12', thumbnailHeight: 1501, quickLogin: false, extra: false, layout: ['Home','Header'],                       color: { 'bg-color': '#f5f6f9', 'color-highlight': '#0a764a', 'color-highlight2': '#0a764a', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 1)',   hyperlink: '#f2d391' } },
      { name: 'ec13', thumbnailHeight: 3218, quickLogin: false, extra: true,  layout: ['Home','Header','Footer'],              color: { 'bg-color': '#efefef', 'color-highlight': '#d0ac54', 'color-highlight2': '#d0ac54', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec20', thumbnailHeight: 1987, quickLogin: false, extra: true,  layout: ['Home','Header'],                       color: { 'bg-color': '#000000', 'color-highlight': '#f8991d', 'color-highlight2': '#FEAE00', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec21', thumbnailHeight: 3323, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#000000', 'color-highlight': '#3e1283', 'color-highlight2': '#3e1283', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec22', thumbnailHeight: 3021, quickLogin: false, extra: true,  layout: ['Home','Header'],                       color: { 'bg-color': '#f5f6f9', 'color-highlight': '#c72828', 'color-highlight2': '#c72828', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec23', thumbnailHeight: 2172, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#1d1d1d', 'color-highlight': '#8ec606', 'color-highlight2': '#8ec606', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec24', thumbnailHeight: 3521, quickLogin: false, extra: true,  layout: ['Home','Header'],                       color: { 'bg-color': '#ededed', 'color-highlight': '#000682', 'color-highlight2': '#000682', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec25', thumbnailHeight: 3262, quickLogin: false, extra: false,  layout: ['Home','Header'],                      color: { 'bg-color': '#ffffff', 'color-highlight': '#005688', 'color-highlight2': '#005688', 'color-secondary': '#005688', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#1dceff' } },
      { name: 'ec26', thumbnailHeight: 2892, quickLogin: false, extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#ededed', 'color-highlight': '#ffc600', 'color-highlight2': '#ffc600', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec28', thumbnailHeight: 3217, quickLogin: true,  extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#27293c', 'color-highlight': '#66cae2', 'color-highlight2': '#66cae2', 'color-secondary': '#66cae2', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#66cae2' } },
      { name: 'ec31', thumbnailHeight: 2873, quickLogin: true,  extra: true,  layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#000000', 'color-highlight': '#f9bb22', 'color-highlight2': '#f9bb22', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec33', thumbnailHeight: 1410, quickLogin: true,  extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#27293c', 'color-highlight': '#fec21e', 'color-highlight2': '#fec21e', 'color-secondary': '#fec21e', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec34', thumbnailHeight: 3045, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#27293c', 'color-highlight': '#f8bc1e', 'color-highlight2': '#f8bc1e', 'color-secondary': '#f8bc1e', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f8bc1e' } },
      { name: 'ec35', thumbnailHeight: 2424, quickLogin: false, extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#27293c', 'color-highlight': '#36477C', 'color-highlight2': '#36477C', 'color-secondary': '#36477C', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#36477C' } },
      { name: 'ec36', thumbnailHeight: 1772, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#0f131d', 'color-highlight': '#0dabff', 'color-highlight2': '#0dabff', 'color-secondary': '#0dabff', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec38', thumbnailHeight: 1579, quickLogin: false, extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#f6f6f6', 'color-highlight': '#013b7a', 'color-highlight2': '#013b7a', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec41', thumbnailHeight: 4034, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#ffffff', 'color-highlight': '#a69539', 'color-highlight2': '#a69539', 'color-secondary': '#fec21e', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec42', thumbnailHeight: 2488, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#ffffff', 'color-highlight': '#00a3e0', 'color-highlight2': '#00a3e0', 'color-secondary': '#00a3e0', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec43', thumbnailHeight: 2763, quickLogin: false, extra: false, layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#f6f6f6', 'color-highlight': '#d01c2d', 'color-highlight2': '#d01c2d', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec45', thumbnailHeight: 1977, quickLogin: true,  extra: true,  layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#27293c', 'color-highlight': '#4d79d7', 'color-highlight2': '#4d79d7', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec47', thumbnailHeight: 1281, quickLogin: false, extra: false, layout: ['Home','Header','Navigation'],          color: { 'bg-color': '#27293c', 'color-highlight': '#880011', 'color-highlight2': '#880011', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec48', thumbnailHeight: 3297, quickLogin: true,  extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#ededed', 'color-highlight': '#CD22F7', 'color-highlight2': '#CD22F7', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec49', thumbnailHeight: 3165, quickLogin: false, extra: false, layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#27293c', 'color-highlight': '#ce2323', 'color-highlight2': '#ce2323', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec50', thumbnailHeight: 3324, quickLogin: false, extra: false, layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#27293c', 'color-highlight': '#d0ad4a', 'color-highlight2': '#ad8a25', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec52', prefetch: true, thumbnailHeight: 2539, quickLogin: true,  extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#f5f6f9', 'color-highlight': '#fb2',    'color-highlight2': '#fb2',    'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec57', prefetch: true, thumbnailHeight: 3104, quickLogin: false, extra: true,  layout: ['Home','Header']                      , color: { 'bg-color': '#f7fbff', 'color-highlight': '#32c5ff', 'color-highlight2': '#32c5ff', 'color-secondary': '#B2A168', 'color-footer': '#1e1f2c', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec59', prefetch: true, thumbnailHeight: 3499, quickLogin: false, extra: false, layout: ['Home','Header','Footer']             , color: { 'bg-color': '#fbfbfa', 'color-highlight': '#d23e63', 'color-highlight2': '#d23e63', 'color-secondary': '#d23e63', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#d23e63' } },
      { name: 'ec61', prefetch: true, thumbnailHeight: 4498, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#ffffff', 'color-highlight': '#d0ad4a', 'color-highlight2': '#ad8a25', 'color-secondary': '#ad8a25', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec60', prefetch: true, thumbnailHeight: 2483, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#ffffff', 'color-highlight': '#e21d1d', 'color-highlight2': '#e21d1d', 'color-secondary': '#fec21e', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#104a9c' } },
      { name: 'ec62', prefetch: true, thumbnailHeight: 3864, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#efefef', 'color-highlight': '#f1c137', 'color-highlight2': '#f1c137', 'color-secondary': '#ff8a00', 'color-footer': '#f3f5f6', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#0f161f' } },
      { name: 'ec63', prefetch: true, thumbnailHeight: 2589, quickLogin: false, extra: false, layout: ['Home','Header'], color: { 'bg-color': '#0f1f2f', 'color-highlight': '#fcc449', 'color-highlight2': '#fcc449', 'color-secondary': '#0a2749', 'color-footer': '#19314b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#edd185'} },
      { name: 'ec64', prefetch: true, thumbnailHeight: 2808, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#303030', 'color-highlight': '#ff7600', 'color-highlight2': '#ff7600', 'color-secondary': '#c87f00', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#f2d391' } },
      { name: 'ec65', prefetch: true, thumbnailHeight: 3636, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#efefef', 'color-highlight': '#05207b', 'color-highlight2': '#05207b', 'color-secondary': '#ff8a00', 'color-footer': '#f3f5f6', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#0f161f' } },
      { name: 'ec66', prefetch: true, thumbnailHeight: 2549, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#efefef', 'color-highlight': '#5235ff', 'color-highlight2': '#392e81', 'color-secondary': '#05c8c6', 'color-footer': '#f3f5f6', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#0f161f' } },
      { name: 'ec67', prefetch: true, thumbnailHeight: 3384, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#27293c', 'color-highlight': '#2d4454', 'color-highlight2': '#2d4454', 'color-secondary': '#3CAAE1', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#3CAAE1' } },
      { name: 'ec68', prefetch: true, thumbnailHeight: 4502, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], components: { Live: 'live1', Egame: 'bcgame', Sports: 'sports3', Mpg: 'bcgame', Animal: 'animal2', Lottery: 'lottery2', Esports: 'esports1', Chess: 'bcgame' }, color: { 'bg-color': '#27293c', 'color-highlight': '#3bc117', 'color-highlight2': '#3bc117', 'color-secondary': '#3CAAE1', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#3CAAE1' } },
      { name: 'ec69', prefetch: true, thumbnailHeight: 3069, quickLogin: false, extra: true,  layout: ['Home','Header','Navigation','Footer'], color: { 'bg-color': '#efefef', 'color-highlight': '#013d74', 'color-highlight2': '#013d74', 'color-secondary': '#3CAAE1', 'color-footer': '#3c3b3b', 'color-sidemenu': 'rgba(255, 255, 255, 0.9)', hyperlink: '#3CAAE1' } },
    ],
    allH5Temp: [
      { name: 'h5app1', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#00aeff', 'm-color-highlight2': '#2347d6', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app2', customSetting: {gpLogoStyle: 'v-primary'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#00aeff', 'm-color-highlight2': '#2347d6', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app3', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#FEB000', 'm-color-highlight2': '#FEAE00', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#111111', 'm-B-bg-gray-color': '#1E1E1E', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#2d2d2d', 'm-B-home-quick-nav-light-bg': '#FEAE00', 'm-B-home-quick-nav-dark-bg': '#FD7900', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#FCC83C' } },
      { name: 'h5app4', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#214635', 'm-color-highlight2': '#214635', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app5', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#d0ac54', 'm-color-highlight2': '#d0ac54', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app6', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'NewsList', 'Banner', 'FunctionBar', 'Navigation', 'GameLobby'] }}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h3', Header: 'template-h3', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#27a9e5', 'm-color-highlight2': '#27a9e5', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app7', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#8d1d11', 'm-color-highlight2': '#8d1d11', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app9', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#FEB000', 'm-color-highlight2': '#FEAE00', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#111111', 'm-B-bg-gray-color': '#1E1E1E', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#2d2d2d', 'm-B-home-quick-nav-light-bg': '#FEAE00', 'm-B-home-quick-nav-dark-bg': '#FD7900', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#FCC83C' } },
      { name: 'h5app10', customSetting: {gpLogoStyle: 'v-primary', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#e5c136', 'm-color-highlight2': '#e5c136', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app11', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'NewsList', 'Banner', 'FunctionBar', 'GameLobby', 'Navigation'] }}, components: {defaultTemplate: 'template', Navigation: 'template-v', FunctionBar: 'template-h2', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#c5ac3d', 'm-color-highlight2': '#5a4f1c', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#111111', 'm-B-bg-gray-color': '#1E1E1E', 'm-B-money-icon-lingt-bg': '#c5ac3d', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#2d2d2d', 'm-B-home-quick-nav-light-bg': '#c5ac3d', 'm-B-home-quick-nav-dark-bg': '#5a4f1c', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#c5ac3d' } },
      { name: 'h5app12', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#3c2d72', 'm-color-highlight2': '#3c2d72', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app13', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#2e9641', 'm-color-highlight2': '#2e9641', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app14', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'NewsList', 'Banner', 'FunctionBar', 'Navigation', 'GameLobby'] }}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h3', Header: 'template-h3', NavBar: 'template', HomePage: 'template-h5app14' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#8ec606', 'm-color-highlight2': '#8ec606', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app15', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#ccab8c', 'm-color-highlight2': '#ccab8c', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app16', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#b636b3', 'm-color-highlight2': '#b636b3', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app17', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#e8e8e8', 'm-color-highlight': '#a23731', 'm-color-highlight2': '#a23731', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app19', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#433336', 'm-color-highlight2': '#433336', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app20', customSetting: {gpLogoStyle: 'v-primary', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#8592c6', 'm-color-highlight2': '#8592c6', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app21', customSetting: {gpLogoStyle: 'v-primary', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#d0ad4a', 'm-color-highlight2': '#ad8a25', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app22', customSetting: {}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#4868E7', 'm-color-highlight2': '#2347d6', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app23', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#ba9979', 'm-color-highlight2': '#ba9979', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app24', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'FunctionBar', 'Banner', 'NewsList', 'Navigation', 'GameLobby']}}, components: {defaultTemplate: 'template', GameList: 'template-h', FunctionBar: 'template-h5app24', Header: 'template-h5app24', HomePage: 'template-h5app24', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#bb8536', 'm-color-highlight2': '#bb8536', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app25', customSetting: {gpLogoStyle: 'v-primary', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#0074E0', 'm-color-highlight2': '#2347d6', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app26', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', GameList: 'template-h', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#ba9979', 'm-color-highlight2': '#ba9979', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app27', customSetting: {}, components: { defaultTemplate: 'template', GameList: 'template-h', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#ba9979', 'm-color-highlight2': '#ba9979', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app28', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#2b2261', 'm-color-highlight2': '#2b2261', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app29', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', GameList: 'template-h', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#2b2261', 'm-color-highlight2': '#2b2261', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app30', customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2', order: { LAYOUT: ['Header', 'Navigation', 'Banner', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app30', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#CD22F7', 'm-color-highlight2': '#CD22F7', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'}},
      { name: 'h5app31', customSetting: {}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#3d72fa', 'm-color-highlight2': '#3d72fa', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app32', customSetting: {gpLogoStyle: 'v-primary', orderType: 'h2'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#b83232', 'm-color-highlight2': '#b83232', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app34', customSetting: {}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#c72828', 'm-color-highlight2': '#c72828', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app35', customSetting: {gpLogoStyle: 'v-dark' }, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#1c2730', 'm-color-highlight2': '#1c2730', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app38', customSetting: {gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#a60105', 'm-color-highlight2': '#a60105', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app39', customSetting: {}, components: { defaultTemplate: 'template', GameList: 'template-h', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#F7821D', 'm-color-highlight2': '#F7821D', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app40', customSetting: {}, components: { defaultTemplate: 'template', GameList: 'template-h', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#F7821D', 'm-color-highlight2': '#F7821D', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app44', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'Navigation', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app44', NavBar: 'template',}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#1c2730', 'm-color-highlight2': '#1c2730', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app45', customSetting: {gpLogoStyle: 'v-primary', order: { LAYOUT: ['Header', 'Banner', 'Navigation', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app45', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#01b3d9', 'm-color-highlight2': '#01b3d9', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app46', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'Navigation', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app46', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#88bf2a', 'm-color-highlight2': '#88bf2a', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app47', customSetting: {gpLogoStyle: 'v-primary', order: { LAYOUT: ['Header', 'Banner', 'FunctionBar', 'NewsList', 'Navigation', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Header: 'template-h3', Navigation: 'template-h3', FunctionBar: 'template-h3', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#004997', 'm-color-highlight2': '#004997', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app48', customSetting: {gpLogoStyle: 'v-primary' }, components: { defaultTemplate: 'template', GameList: 'template-h', FunctionBar: 'template-h3', Header: 'template-h3', Navigation: 'template-nav-row2', NavBar: 'template', Animal: 'template-submenu', Chess: 'template-submenu', Egame: 'template-submenu-full', Esports: 'template-submenu', Finance: 'template-submenu', Fishing: 'template-submenu', Live: 'template-submenu', Lottery: 'template-submenu', MiniGames: 'template-submenu', Sports: 'template-submenu' }, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#120b6f', 'm-color-highlight2': '#120b6f', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' }, },
      { name: 'h5app49', customSetting: {gpLogoStyle: 'v-dark' }, components: { defaultTemplate: 'template', GameList: 'template-h', FunctionBar: 'template-h3', Header: 'template-h3', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#cb9e40', 'm-color-highlight2': '#cb9e40', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app50', customSetting: {gpIconStyle: 'icon-dark', gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#890000', 'm-color-highlight2': '#890000', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app51', customSetting: {gpLogoStyle: 'v-dark' }, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#cb9e40', 'm-color-highlight2': '#cb9e40', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app52', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'FunctionBar', 'NewsList', 'Navigation', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h3', Header: 'template-h3', HomePage: 'template-h5app52', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#426871', 'm-color-highlight2': '#426871', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app53', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'NewsList', 'Banner', 'FunctionBar', 'Navigation', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', Header: 'template-h3-login', HomePage: 'template-h5app53', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#6700f1', 'm-color-highlight2': '#6700f1', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app54', customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'NewsList', 'FunctionBar', 'Navigation', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h2', Header: 'template-h3-login', NavBar: 'template', Live: 'template-submenu' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#b6272c', 'm-color-highlight2': '#b6272c', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app55', customSetting: {order: { LAYOUT: ['Header', 'Navigation', 'Banner', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', Header: 'template-h3', NavBar: 'template' }, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#181c42', 'm-color-highlight2': '#181c42', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app56', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['FunctionBar', 'Header', 'Navigation', 'Banner', 'GameLobby', 'NewsList']}, isContactExisted: true, isSportsPlatformExisted: true}, components: {defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h5app56', Header: 'template-h5app56', HomePage: 'template-h5app56', NavBar: 'template', Chess: 'template-classic', Egame: 'template-classic', Fishing: 'template-classic'}, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#294977', 'm-color-highlight2': '#294977', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app57', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'NewsList', 'FunctionBar', 'Navigation', 'GameLobby']}}, components: {defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h5app57', Header: 'template-h5app57', HomePage: 'template-h5app57', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#af0000', 'm-color-highlight2': '#af0000', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app58', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'FunctionBar', 'Banner', 'NewsList', 'Navigation', 'GameLobby']}}, components: {defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h3', HomePage: 'template-h5app58', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#f9bb22', 'm-color-highlight2': '#f9bb22', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C' } },
      { name: 'h5app59', prefetch: true, customSetting: {gpIconStyle: 'icon-dark', gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', FunctionBar: 'template-h5app59', Header: 'template-h5app59', NavBar: 'template-h5app59', Chess: 'template-classic', Egame: 'template-classic', Fishing: 'template-classic', Lottery: 'template-classic'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#ca9a31', 'm-color-highlight2': '#ca9a31', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app60', prefetch: true, customSetting: {gpLogoStyle: 'v-primary', gpIconStyle: 'icon-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-h2', Header: 'template-h3', GameList: 'template-h', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#ff7600', 'm-color-highlight2': '#ff7600', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app61', prefetch: true, customSetting: {gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', FunctionBar: 'template-h5app61', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#fcc449', 'm-color-highlight2': '#fcc449', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#111111', 'm-B-bg-gray-color': '#1E1E1E', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#2d2d2d', 'm-B-home-quick-nav-light-bg': '#FEAE00', 'm-B-home-quick-nav-dark-bg': '#FD7900', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#FCC83C'} },
      { name: 'h5app62', prefetch: true, customSetting: {gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#FEB000', 'm-color-highlight2': '#FEAE00', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#111111', 'm-B-bg-gray-color': '#1E1E1E', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#2d2d2d', 'm-B-home-quick-nav-light-bg': '#FEAE00', 'm-B-home-quick-nav-dark-bg': '#FD7900', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#FCC83C'} },
      { name: 'h5app63', prefetch: true, customSetting: {gpIconStyle: 'icon-dark', gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-h2', GameList: 'template-h', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#7240ff', 'm-color-highlight2': '#7240ff', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app64', prefetch: true, customSetting: {gpIconStyle: 'icon-dark', gpLogoStyle: 'v-dark'}, components: { defaultTemplate: 'template', Navigation: 'template-v', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#7240ff', 'm-color-highlight2': '#7240ff', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app65', prefetch: true, customSetting: {gpLogoStyle: 'v-dark',}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app65', NavBar: 'template-h5app65'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#2d4454', 'm-color-highlight2': '#2d4454', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app66', prefetch: true, customSetting: {gpLogoStyle: 'v-dark',}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app66', NavBar: 'template-h5app66'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#3bc117', 'm-color-highlight2': '#3bc117', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app67', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', orderType: 'h2', order: { LAYOUT: ['Header', 'Banner', 'Navigation', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app67', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#f5f6f9', 'm-color-highlight': '#CD22F7', 'm-color-highlight2': '#CD22F7', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app68', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Banner', 'NewsList', 'FunctionBar', 'Navigation', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h3', FunctionBar: 'template-h5app68', Header: 'template-h5app68', HomePage: 'template-h5app68', NavBar: 'template',Chess: 'template-classic-gpk', Egame: 'template-classic-gpk',Fishing: 'template-classic-gpk'}, colorMobile: { 'm-bg-color': '#efefef', 'm-color-highlight': '#013d74', 'm-color-highlight2': '#013d74', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C'} },
      { name: 'h5app69', prefetch: true, customSetting: {gpLogoStyle: 'v-dark', order: { LAYOUT: ['Header', 'Navigation', 'Banner', 'NewsList', 'FunctionBar', 'GameLobby']}}, components: { defaultTemplate: 'template', GameList: 'template-h', Navigation: 'template-h2', FunctionBar: 'template-h2', HomePage: 'template-h5app69', NavBar: 'template'}, colorMobile: { 'm-bg-color': '#ffffff', 'm-color-highlight': '#3bc117', 'm-color-highlight2': '#3bc117', 'm-color-require': '#dd9c4b', 'm-color-secondary': '#8FC31F', 'm-color-coin': '#ffc446', 'm-color-border': '#e7e7e7', 'm-light-bg': '#f0eff5', 'm-bottom-nav-dot': '#E94951', 'm-W-bg-color': '#ffffff', 'm-B-bg-color': '#272b3a', 'm-B-bg-gray-color': '#394462', 'm-B-money-icon-lingt-bg': '#FCC83C', 'm-B-money-icon-dark-bg': '#F8971C', 'm-B-sub-menu': '#4868E7', 'm-B-home-quick-nav-light-bg': '#4868E7', 'm-B-home-quick-nav-dark-bg': '#2347D6', 'm-B-bottom-nav': '#131313', 'm-B-bottom-nav-normal-text': 'rgba(255,255,255,0.4)', 'm-B-bottom-nav-highlight-text': '#DCAB4C', } },
      
    ]
  }
export default templateConfig;
