import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import PageLoading from 'components/Common/PageLoading';
import { isSupportWebp } from 'libraries/utils';
import { useDispatch } from 'react-redux';
import { UpdateImgFormatSupport } from 'redux/actions/template';
import { prefetchImg } from 'libraries/prefetch';
import 'stylesheets/app.scss';

let isPrefetch = false;

const App = () => {
  const [initial, setInitial] = useState(false);
  const location = useLocation();
  const routeName = location.pathname.substring(1);
  const dispatch = useDispatch();
  const setWindowInnerHeight = () => {
    document.documentElement.style.setProperty('--window-inner-height', `${window.innerHeight}px`);
  };
  useEffect(() => {
    // 先偵測是否支援 webp 格式
    isSupportWebp('lossy', function (feature, isSupported) {
      dispatch(UpdateImgFormatSupport(isSupported));
      if (!isPrefetch) {
        isPrefetch = true;
        // 放入 link img prefetch
        prefetchImg(isSupported);
      }
      setInitial(true);
    });
    window.addEventListener('resize', setWindowInnerHeight);
    return () => {
      window.removeEventListener('resize', setWindowInnerHeight);
    };
  }, [dispatch]);
  return (
    <Suspense fallback={<PageLoading />}>
      <div className={`app ${routeName}`}>
        <Header />
        <div className='home-container'>{initial && <Outlet />}</div>
        <Footer />
      </div>
    </Suspense>
  );
};

export default App;
