import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';


const News = ({ t }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;
    document.getElementById('NewsInner').appendChild(script);
    return () => {
      document.getElementById('NewsInner').removeChild(script);
    };
  })
  
  return (
    <div className='page-news'>
      <section className='news-top'>
        <div className='main-wrap'>
          <div className='small-text-tag'>{t('news.top.tag')}</div>
          <h1><strong>{t('news.top.title1')}</strong></h1>
        </div> 
      </section>
      <div className='news-cont' id='NewsInner'>
      <div className="elfsight-app-4a683ff3-0992-4c90-a188-bbd2d3abf532"></div>
      </div>
    </div>
  );
};

export default withTranslation()(News);
