import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { k36Langs } from 'libraries/enums';

const getResources = () =>{
  k36Langs.forEach((lang) => {
    import(`./../locales/${lang}.js`)
      .then((res) => {
        i18n.addResourceBundle(lang, 'translation', res.default);
      });
  });
}

const getValidLanguage = () => {
  const storedLang = window.localStorage.getItem('locale');
  const navigatorLang = navigator.language;
  const params = new URLSearchParams(window.location.search);
  const urlLang = params.get('lang');

  // 先檢查 URL 語言
  if (urlLang && k36Langs.includes(urlLang)) {
    return urlLang;
  }
  // 再檢查 localStorage 語言
  if (storedLang && k36Langs.includes(storedLang)) {
    return storedLang;
  }
  // 最後檢查瀏覽器語言
  if (navigatorLang && k36Langs.includes(navigatorLang)) {
    return navigatorLang;
  }
  // 如果都不符合，顯示預設語言英文
  return k36Langs[0];
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    },
    lng: getValidLanguage(),
    fallbackLng: k36Langs[0], // 如果當前切換的語言沒有對應的翻譯則使用這個語言
    resources: getResources(),
    interpolation: {
      escapeValue: false
    }
  });
